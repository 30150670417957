@charset "utf-8";

// Import a Google Font TODO: user typography?
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

// Import variables
@import './variables';

// root container
#root {
  height: 100%;
}

// disabled href
a[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
  text-decoration: none;
}

// Import only what you need from Bulma
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/base/_all.sass';
@import '~bulma/sass/form/_all.sass';
@import '~bulma/sass/grid/_all.sass';
@import '~bulma/sass/elements/image.sass';
@import '~bulma/sass/elements/container.sass';
@import '~bulma/sass/elements/content.sass';
@import '~bulma/sass/elements/button.sass';
@import '~bulma/sass/elements/tag.sass';
@import '~bulma/sass/elements/other.sass';
@import '~bulma/sass/elements/progress.sass';
@import '~bulma/sass/elements/title.sass';
@import '~bulma/sass/elements/table.sass';
@import '~bulma/sass/components/dropdown.sass';
@import '~bulma/sass/components/navbar.sass';
@import '~bulma/sass/components/card.sass';
@import '~bulma/sass/components/tabs.sass';
@import '~bulma/sass/components/message.sass';
@import '~bulma/sass/components/modal.sass';
// @import '~bulma-tooltip/';
