.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-flow: wrap;
    margin: 1em 0;

    & > a:not(:first-child) {
        margin-left: 1em;
    }
}