@import '../variables';

.main {
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin: auto;
  margin-bottom: $card-margin-bottom;
  max-width: 940px;
  padding: 2rem 4rem;
  font-weight: 400;
  background: #fff;
}
