@import '../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../node_modules/bulma/sass/components/tabs.sass';

.tabs {
  margin-bottom: 0 !important;
}

.tab {
  display: none;
  background-color: white;
  border: 1px solid transparent;
  border-color: $border;
  border-top: none;
  border-radius: 0 0 $tabs-boxed-link-radius $tabs-boxed-link-radius;
  margin-bottom: 2em;
  padding: 2em;

  &.active {
    display: inherit;
  }
}
