$navbar-height: 5rem;
$card-margin-bottom: 40px;

// Set your brand colors
// $purple: #8a4d76;
// $pink: #fa7c91;
// $brown: #757763;
// $beige-light: #d0d1cd;
// $beige-lighter: #eff0eb;

// Update Bulma's global variables
// $family-sans-serif: 'Nunito', sans-serif;
// $grey-dark: $brown;
// $grey-light: $beige-light;
$primary: #cb2b6f;
// $link: $pink;
// $widescreen-enabled: false;
// $fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: rgb(250, 250, 250);
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;
// $radius-rounded: 100%;

@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.9;
  }
}
