.balance {
  margin-right: 1em;
  font-size: 2em;
}

$revenues-color: #d2bc34;
$total-revenues-color: #d7a029;
$total-sells-color: rgba(29, 162, 29, 0.72);
$invoices-color: #50b050;
$expenses-color: #ad392a;
$total-expenses-color: pink;
$empty-color: #f3f3f3;
$empty-color-darken: darken($empty-color, 5%);

.bars {
  background-color: #e2e2e2;
  background: repeating-linear-gradient(
    45deg,
    $empty-color,
    $empty-color 10px,
    $empty-color-darken 10px,
    $empty-color-darken 20px
  );
}

.bar {
  display: flex;
  align-items: center;
  padding: 1em;
  height: 4em;
  width: 100%;
  font-weight: bold;
  font-family: Quicksand;
  letter-spacing: 0.05em;
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px solid #9b9b9b;
  }

  &.wrapped {
    position: absolute;
    top: 0;
    left: 0;
  }

  & > .message {
    position: absolute;
  }
}

.coloredBar {
  height: 4em;
  position: absolute;
  left: 0;
  top: 0;
}

.barColorBg {
  position: absolute;
  height: 4em;
  width: 100%;
  left: 0;
  top: 0;

  &.totalSells {
    background-color: $total-sells-color;
    mix-blend-mode: multiply;
  }

  &.invoices {
    background-color: $invoices-color;
  }

  &.revenues {
    background-color: $revenues-color;
  }

  &.totalRevenues {
    background-color: $total-revenues-color;
    mix-blend-mode: multiply;
  }

  &.expenses {
    background-color: $expenses-color;
  }

  &.totalExpenses {
    background-color: $total-expenses-color;
    mix-blend-mode: multiply;
  }
}
